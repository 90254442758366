import React from 'react'
import SimpleBanner from '../Common/SimpleBanner';
import DetailSection from '../../Components/TourDetails/DetailiSection';
import ClientsTestimonials from '../../Components/About Us/ClientsTestimonials';
import YouMayLike from '../../Components/TourDetails/YouMayLike';
import Newsletter from '../../Components/home/Newsletter';
import Aboutbanar from '../../Assets/TourDetails/topbanner.png'
import Chilika from '../../Assets/Tour/Chilika.jpg'
import Ankita from '../../Assets/TourDetails/ankita.jpg';
import Pritam from '../../Assets/TourDetails/pritam.jpg';

const ChilikaTour = () => {
    const tripData = {
        title: "Explore the Wonders of Chilika Lake",
        imageUrl: Chilika,  
        details: [
            { icon: "calendar_icon", label: "Duration", value: "7 days" },
            { icon: "people_icon", label: "Max People", value: "30" },
            { icon: "age_icon", label: "Min Age", value: "10+" },
            { icon: "location_icon", label: "Pick Up", value: "Bhubaneswar Airport" }
        ],

        content: {
            description: "Discover the beauty of Chilika Lake, Asia's largest brackish water lagoon. Home to a rich variety of wildlife, including migratory birds, dolphins, and lush flora, Chilika offers a serene and picturesque retreat for nature enthusiasts.",
            program: {
                summary: "This seven-day journey around Chilika Lake includes boat rides, wildlife spotting, visits to charming islands, and local cultural experiences. It's a perfect getaway for those who love nature and wildlife.",
                days: [
                    {
                        day: 1,
                        title: "Arrival in Bhubaneswar & Transfer to Chilika",
                        description: "Arrive at Bhubaneswar Airport and drive to your lakeside resort near Chilika. Enjoy a welcome dinner with a stunning view of the lake at sunset."
                    },
                    {
                        day: 2,
                        title: "Boat Safari to Kalijai Island",
                        description: "Embark on a morning boat safari to Kalijai Island, known for its ancient temple. Explore the serene surroundings and enjoy bird watching during the boat ride."
                    },
                    {
                        day: 3,
                        title: "Bird Watching at Nalabana Sanctuary",
                        description: "Spend the day at Nalabana Bird Sanctuary, a haven for migratory birds. Spot rare species and enjoy a guided tour by a local wildlife expert."
                    },
                    {
                        day: 4,
                        title: "Dolphin Watching at Satapada",
                        description: "Travel to Satapada, famous for its sightings of the rare Irrawaddy dolphins. Take a boat ride through the lagoon and witness these fascinating creatures in their natural habitat."
                    },
                    {
                        day: 5,
                        title: "Visit to Rambha Bay & Local Villages",
                        description: "Explore Rambha Bay, the southern part of Chilika, known for its scenic beauty and rock formations. Visit local fishing villages to learn about traditional livelihoods and enjoy local cuisine."
                    },
                    {
                        day: 6,
                        title: "Island Hopping & Relaxation",
                        description: "Spend the day hopping between some of Chilika's most beautiful islands like Honeymoon Island and Breakfast Island. Relax, swim, and indulge in a local seafood feast."
                    },
                    {
                        day: 7,
                        title: "Departure from Chilika",
                        description: "Enjoy a final morning by the lake, soaking in the tranquility of Chilika. Check out from your resort and transfer back to Bhubaneswar Airport for your journey home."
                    }
                ]
            },
            review: {
                rating: 4.8,
                ratingText: "Fantastic",
                ratingDescription:"Chilika Lake is a serene haven, renowned for its stunning sunsets, diverse birdlife, and tranquil waters—perfect for nature and birdwatching enthusiasts.",
                reviewCount: 37,
                summary: "Chilika Lake is a paradise for bird watchers and nature lovers. The serene environment, diverse wildlife, and cultural richness make it a memorable destination for a peaceful vacation.",
                reviews: [
                    {
                        reviewerName: "Ankita Das",
                        reviewerImage: Ankita, // Replace with the actual image path
                        rating: 5,
                        reviewText: "A stunning experience! Loved the bird watching and the boat rides. The local seafood was amazing, and the sunsets were simply breathtaking.",
                        replyText: "Reply"
                    },
                    {
                        reviewerName: "Pritam Choudhury",
                        reviewerImage: Pritam, // Replace with the actual image path
                        rating: 4,
                        reviewText: "The dolphin spotting was the highlight of the trip. A peaceful getaway with lots to explore. Highly recommend the island tours!",
                        replyText: "Reply"
                    }
                ]
            },
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19246.592184683715!2d85.33339815129479!3d20.41787475943009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a18f23216238423%3A0x6777cfcaa00c7af!2sChilika%20Lake%2C%20Odisha!5e0!3m2!1sen!2sin!4v1731786543211!5m2!1sen!2sin"
        }
    };
    return (
        <div>
            <SimpleBanner
                image={Aboutbanar}
                name="Tour Details"
                path="Home / Tour Details"
            />
            <DetailSection tripData={tripData} />
            <ClientsTestimonials />
            <YouMayLike />
            <Newsletter />
        </div>
    )
}

export default ChilikaTour