import React from 'react'
import image from '../../Assets/homeimgs/Newsletterbg.png'
import img1 from '../../Assets/homeimgs/Newsletterimg.png'
const Newsletter = () => {
    return (
        <div className='flex flex-col md:flex-row items-center justify-center'
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
            data-aos="zoom-in">
            <img src={img1} alt='img' className='md:ml-56' />
            <div className='flex flex-col'>
                <h1
                     className="text-3xl text-center md:text-4xl font-bold text-[#292929] tracking-wide mb-2"
                     style={{ fontFamily: "DM Sans, sans-serif" }}
                >
                    Get special offers, and more from Traveler
                </h1>
                <p
                  className="text-[#767676] text-center mx-auto w-full lg:w-[50%] mb-4 capitalize leading-relaxed"
                >
                    Subscribe to see secret deals prices
                    drop the moment you sign up!
                </p>
                <div className="mt-8 flex justify-center" data-aos="flip-down">
                    <div className="relative w-full max-w-[80%]">
                        <input
                            type="email"
                            placeholder="Email Address"
                            className="w-full p-4 pr-32 text-gray-700 bg-white border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-[#FBA710]"
                        />
                        <button
                            className="absolute top-1 right-3 tracking-wide bg-[#0E5A00] hover:bg-[#033b00] text-white font-bold py-3 px-4 rounded">
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsletter