import React from "react";
import TopDestinations from "../../Components/Tour/TopDestinations";
import TrendingTour from "../../Components/home/TrendingTours";
import OurRecords from "../../Components/Tour/OurRecords";
import VehicleService from "../../Components/Tour/VehicleService";
import SimpleBanner from "../../Screens/Common/SimpleBanner";
import TourBanner from "../../Assets/Tour/tourBg.png";
import Newsletter from "../../Components/home/Newsletter";

const Tour = () => {
  return (
    <div className="overflow-hidden">
      <SimpleBanner
        image={TourBanner}
        name="Our Tours"
        path="Home / Our Tours"
      />

      <TopDestinations />
      <TrendingTour />
      <OurRecords />
      <VehicleService />
      <Newsletter />
    </div>
  );
};

export default Tour;
