import React from 'react'
import SimpleBanner from '../Common/SimpleBanner';
import DetailSection from '../../Components/TourDetails/DetailiSection';
import ClientsTestimonials from '../../Components/About Us/ClientsTestimonials';
import YouMayLike from '../../Components/TourDetails/YouMayLike';
import Newsletter from '../../Components/home/Newsletter';
import Aboutbanar from '../../Assets/TourDetails/topbanner.png'
import Iskcon from '../../Assets/Tour/ISKCONTemple.png';
import Ankita from '../../Assets/TourDetails/ankita.jpg';
import Anita from '../../Assets/TourDetails/anita.jpg'

const IskconTempleTour = () => {
    const tripData = {
        title: "Discover the Spiritual Essence of ISKCON Temple, Bhubaneswar",
        imageUrl: Iskcon, // Replace with the actual image path
        details: [
            { icon: "calendar_icon", label: "Duration", value: "1 day" },
            { icon: "people_icon", label: "Max People", value: "100" },
            { icon: "age_icon", label: "Min Age", value: "5+" },
            { icon: "location_icon", label: "Pick Up", value: "Bhubaneswar Railway Station" }
        ],

        content: {
            description: "The ISKCON Temple in Bhubaneswar is a renowned spiritual center dedicated to Lord Krishna. Famous for its vibrant atmosphere, stunning architecture, and devotion-filled ambiance, the temple is an ideal destination for spiritual seekers and those looking for inner peace.",
            program: {
                summary: "This one-day visit will take you through the serene temple grounds, the cultural significance of ISKCON, and the powerful bhajans and kirtans performed by devotees. You will also have the opportunity to witness the temple's beautiful rituals and participate in spiritual activities.",
                days: [
                    {
                        day: 1,
                        title: "ISKCON Temple Visit",
                        description: "Start your day with a visit to the ISKCON Temple. Enjoy the mesmerizing morning prayers, immerse in the vibrant spiritual atmosphere, and explore the temple's surroundings. You will also get a chance to witness the famous bhajan sessions and learn about the teachings of ISKCON."
                    }
                ]
            },
            review: {
                rating: 4.9,
                ratingText: "Divine & Peaceful",
                ratingDescription: "ISKCON Temple in Bhubaneswar is a peaceful spiritual retreat, featuring stunning architecture, serene surroundings, and a vibrant atmosphere for devotees and visitors alike.",
                reviewCount: 40,
                summary: "The ISKCON Temple offers a serene and divine experience with its majestic architecture, spiritual activities, and the ever-present chanting of Lord Krishna’s name. A must-visit for those seeking spiritual peace and divine connection.",
                reviews: [
                    {
                        reviewerName: "Ankita Das",
                        reviewerImage: Ankita, // Replace with the actual image path
                        rating: 5,
                        reviewText: "A truly divine place. The atmosphere is filled with peace and positive energy. The bhajans and kirtans were soul-stirring. Highly recommended for all spiritual seekers.",
                        replyText: "Reply"
                    },
                    {
                        reviewerName: "Anita Verma",
                        reviewerImage: Anita, // Replace with the actual image path
                        rating: 5,
                        reviewText: "An amazing experience! The temple is beautiful, and the serenity it offers is unmatched. I enjoyed every moment spent here. A great place for peace and reflection.",
                        replyText: "Reply"
                    }
                ]
            },
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12238.138199171906!2d85.82901545443814!3d20.303522803169523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1908f7f3128b95%3A0x1f213b7b913efee2"
        }
    }
    return (
        <div>
            <SimpleBanner
                image={Aboutbanar}
                name="Tour Details"
                path="Home / Tour Details"
            />
            <DetailSection tripData={tripData} />
            <ClientsTestimonials />
            <YouMayLike />
            <Newsletter />
        </div>
    )
}

export default IskconTempleTour