import React, { useState } from "react";
import emailjs from "emailjs-com";
import img1 from "../../Assets/Contact/location.png";
import img2 from "../../Assets/Contact/email.png";
import img3 from "../../Assets/Contact/telephone.png";
import name from "../../Assets/Contact/name.png";
import email from "../../Assets/Contact/formEmail.png";
import phone from "../../Assets/Contact/phone.png";
import message from "../../Assets/Contact/message.png";
import departureCity from "../../Assets/Contact/departureCity.png";
import calender from "../../Assets/Contact/calender.png";
import children from "../../Assets/Contact/children.png";
import adult from "../../Assets/Contact/adult.png";

const StayConnected = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = "Name is required";
    if (!formValues.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formValues.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formValues.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formValues.message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);

      // Sending email with EmailJS
      const formattedData = {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
        message: formValues.message,
        departureCity: formValues.departureCity,
        date: formValues.date,
        children: formValues.children,
        adults: formValues.adults,
      };

      console.log("Formatted Data:", formattedData);

      // Sending email with EmailJS
      emailjs
        .send(
          "service_qda9r1p",
          "template_woapl0d",
          formattedData, // Send the consolidated object
          "NcGgN4drK0cL1rQnO"
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccessMessage("Message sent successfully!");
          setFormValues({
            name: "",
            email: "",
            phone: "",
            message: "",
            departureCity: "",
            date: "",
            children: "",
            adults: "",
          });
          setErrors({});
        })
        .catch((error) => {
          console.error("FAILED...", error);
          setErrors({ form: "Failed to send message, please try again." });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };
  // function DateFieldWithPlaceholder() {
  //   const [inputType, setInputType] = useState("text");
  //   const [date, setDate] = useState("");

  //   const handleFocus = () => {
  //     setInputType("date");
  //   };

  //   const handleBlur = () => {
  //     if (!date) {
  //       setInputType("text"); // Revert to text if no date is selected
  //     }
  //   };

  //   return (
  //     <input
  //       type={inputType}
  //       value={date}
  //       onChange={(e) => setDate(e.target.value)}
  //       onFocus={handleFocus}
  //       onBlur={handleBlur}
  //       id="date"
  //       className="w-full p-2 pl-8 lg:pl-4 xl:pl-8 xl-pl-8 border border-l-[#949292] outline-none opacity-80 "
  //       placeholder="Departure Date"
  //     />
  //   );
  // }

  return (
    <div className="mx-6 md:mx-16 my-4 md:my-8 ">
      <div className="flex flex-col items-center">
        <h1
          style={{ fontFamily: "DM Sans, sans-serif" }}
          className="text-3xl sm:text-4xl font-bold text-center text-[#292929] tracking-wide "
        >
          Stay Connected
        </h1>
        <p
          style={{ fontFamily: "'Figtree', sans-serif" }}
          className="text-center text-[#595959] w-full lg:w-[50%] text-base my-4"
        >
          We’d love to hear from you! Whether you're looking to book an
          appointment or have questions about our services, feel free to reach
          out to us.
        </p>
      </div>

      <div className="flex flex-col lg:flex-row ">
        {/* Contact Details Section */}
        <div className="lg:w-[40%] xl:w-1/3 bg-[#E9B301] p-8 py-6 lg:p-8 xl:p-12 lg:py-24 xl:py-24 text-white rounded-md items-start"data-aos="fade-down">
          <div className="mb-12 flex lg:flex-row gap-2 md:gap-8 items-start lg:items-center flex-col">
            <img src={img1} alt="img" />
            <div className="flex flex-col">
              <h1
                style={{ fontFamily: "Lato, sans-serif" }}
                className="font-bold text-lg md:text-xl "
              >
                Address
              </h1>
              <p
                className="mt-2 text-sm sm:text-md leading-relaxed opacity-80"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Gpo, Plot No 298, Unit-6, Biju Patnaik International
                Airport-Aerodrome, Ekamra Rd, Ganga Nagar, Bhubaneswar, Odisha
                751020
              </p>
            </div>
          </div>

          <div className="mb-12 flex flex-col gap-2 md:gap-8 items-start lg:items-center lg:flex-row ">
            <img src={img2} alt="img" />
            <div className="flex flex-col">
              <h1
                style={{ fontFamily: "Lato, sans-serif" }}
                className="font-bold text-lg md:text-xl "
              >
                Email
              </h1>
              <p
                className="mt-2 text-sm sm:text-md leading-relaxed opacity-80"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                rameshbarad264@gmail.com
              </p>
            </div>
          </div>

          <div className="flex gap-2 flex-col lg:flex-row md:gap-8 items-start lg:items-center  ">
            <img src={img3} alt="img" />
            <div className="flex flex-col">
              <h1
                style={{ fontFamily: "Lato, sans-serif" }}
                className="font-bold text-lg md:text-xl "
              >
                Phone
              </h1>
              <p
                className="mt-2 text-sm sm:text-md leading-relaxed opacity-80"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                +91 824 968 6752
              </p>
            </div>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="lg:w-[60%] xl:w-2/3 bg-orange-50 p-6 md:p-16 rounded-md"data-aos="fade-left">
          <h2
            style={{ fontFamily: "DM Sans, sans-serif" }}
            className="text-2xl sm:text-3xl font-bold mb-6 md:mb-8 tracking-wide "
          >
            Get In Touch
          </h2>
          {successMessage && (
            <p className="text-green-500 text-sm mb-4">{successMessage}</p>
          )}
          {errors.form && (
            <p className="text-red-500 text-sm mb-4">{errors.form}</p>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-8 flex border border-[#949292]">
              <img src={name} alt="" className="p-4 bg-white w-14" />
              <input
                className="w-full p-2 pl-8 lg:pl-4 xl:pl-8 border border-l-[#949292] outline-none opacity-80 "
                type="text"
                id="name"
                placeholder="Name"
                value={formValues.name}
                onChange={handleChange}
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
            </div>
            <div className="mb-8 flex border border-[#949292]">
              <img src={email} alt="" className="p-4 bg-white w-14" />
              <input
                className="w-full p-2 pl-8 lg:pl-4 xl:pl-8 border border-l-[#949292] outline-none opacity-80 "
                type="email"
                id="email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleChange}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <div className="mb-8 flex border border-[#949292]">
              <img src={phone} alt="" className="p-4 bg-white w-14" />
              <input
                className="w-full p-2 pl-8 lg:pl-4 xl:pl-8 border border-l-[#949292] outline-none opacity-80 "
                type="text"
                id="phone"
                placeholder="Phone"
                value={formValues.phone}
                onChange={handleChange}
              />
              {errors.phone && (
                <p className="text-red-500 text-sm">{errors.phone}</p>
              )}
            </div>
            <div className="mb-8 flex border border-[#949292]">
              <img src={message} alt="" className="p-4 bg-white w-14" />
              <input
                className="w-full p-2 pl-8 lg:pl-4 xl:pl-8 border border-l-[#949292] outline-none opacity-80 "
                id="message"
                placeholder="Message"
                value={formValues.message}
                onChange={handleChange}
              ></input>
              {errors.message && (
                <p className="text-red-500 text-sm">{errors.message}</p>
              )}
            </div>

            <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
              <div className="flex-1 mb-8 flex border border-[#949292]">
                <img
                  src={departureCity}
                  alt=""
                  className="p-4 bg-white w-14 opacity-80"
                />
                <input
                  className="w-full p-2 pl-8 lg:pl-4 xl:pl-8 border border-l-[#949292] outline-none opacity-80 "
                  id="city"
                  placeholder="Departure City"
                  value={formValues.departureCity}
                  onChange={handleChange}
                />

                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>

              <div className="flex-1 mb-8 flex border border-[#949292]">
                <img
                  src={calender}
                  alt=""
                  className="p-4 bg-white w-14 opacity-80"
                />

                <input
                  className="w-full p-2 pl-8 border-l-gray-400 outline-none"
                  type="date"
                  id="date"
                  value={formValues.date}
                  onChange={handleChange}
                />

                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
              <div className="flex-1 mb-8 flex border border-[#949292]">
                <img
                  src={children}
                  alt="no. of children"
                  className="p-4 bg-white w-14 opacity-80"
                />
                <input
                  className="w-full p-2 pl-8 lg:pl-4 xl:pl-8  border border-l-[#949292] outline-none opacity-80 "
                  id="children"
                  placeholder="No. of Children"
                  value={formValues.children}
                  onChange={handleChange}
                ></input>

                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>

              <div className="flex-1 mb-8 flex border border-[#949292]">
                <img
                  src={adult}
                  alt="no. of adults"
                  className="p-4 bg-white w-14 opacity-80"
                />
                <input
                  className="w-full p-2 pl-8 lg:pl-4 xl:pl-8 xl-pl-8 border border-l-[#949292] outline-none opacity-80 "
                  id="children"
                  placeholder="No. of Adults"
                  value={formValues.adults}
                  onChange={handleChange}
                />

                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="bg-[#FCC101] hover:bg-[#ca9a04] text-white px-6 py-3 font-medium rounded shadow-xl w-full md:w-1/3 xl:w-1/4"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StayConnected;
