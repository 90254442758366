import React from "react";
import Stars from "../../Assets/About/Stars.png";
import avatar from "../../Assets/About/avatar.png";
import Quotation from "../../Assets/About/Quotation.png";
import Line from "../../Assets/About/Line.png";
import Jeeban from '../../Assets/homeimgs/jeeban.jpg';
import Tanya from '../../Assets/homeimgs/Tanya.jpg';
import Sradha from '../../Assets/homeimgs/Sradha.webp'

const data = [
  {
    id: 1,
    rating: "(4.0)",
    startImg: Stars,
    content: `"Gocabz made our trip unforgettable! Everything was perfectly organized, and our guide was both friendly and knowledgeable, revealing hidden gems we’d never discover alone. Incredible service and attention to detail."`,
    avatar: Tanya,
    name: "Tanya Chakraborty",
    role: "Traveller",
    aos: "flip-right"
  },

  {
    id: 2,
    rating: "(4.0)",
    startImg: Stars,
    content: `"Our experience with Gocabz was fantastic! The trip was meticulously planned, and our guide’s expertise made it even better. We explored unique, off-the-beaten-path locations we wouldn’t have found otherwise."`,
    avatar: Jeeban,
    name: "Jeeban Acharya",
    role: "Traveller",
    aos: "flip-right"
  },

  {
    id: 3,
    rating: "(4.0)",
    startImg: Stars,
    content: `"Gocabz exceeded our expectations! The itinerary was seamless, and our guide’s passion brought the destination to life. We discovered amazing local spots, making the trip truly memorable and stress-free."`,
    avatar: Sradha,
    name: "Sradha Mandal",
    role: "Traveller",
    aos: "flip-right"
    
  },
];

function ClientsTestimonials() {
  return (
    <div className="mx-6 md:mx-16 my-6 md:my-16 flex flex-col items-center">
      <h1
        className="text-3xl md:text-4xl font-bold text-[#292929] tracking-wide mb-4 text-center"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        Clients Testimonials
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:flex-row gap-4 xl:gap-8">
        {data.map((data) => (
          <div className="flex flex-col p-6 md:p-8 border border-[#FBA710] hover:bg-[#FFFBEF]"data-aos={data.aos}>
            <div className="flex justify-between items-center mb-8">
              <div className="flex justify-start gap-2 items-center ">
                <p
                  className="text-sm text-[#5B5A5A]"
                  style={{ fontFamily: "'Nunito Sans', sans-serif" }}
                >
                  {data.rating}
                </p>
                <img src={data.startImg} alt="Rating in Stars" />
              </div>
              <img src={Quotation} alt="A Quotation image" className="h-6" />
            </div>
            <p
              className="text-left  text-[#5B5A5A] text-sm leading-relaxed mb-4"
              style={{ fontFamily: "'Nunito Sans', sans-serif" }}
            >
              {data.content}
            </p>
            <img src={Line} alt="" />
            <div className="flex justify-start gap-6 items-center mt-8">
              <img src={data.avatar} alt="Profile icon of the reviewer" className="w-14 h-14 rounded-full"/>
              <div className="flex flex-col ">
                <h1
                  className="text-md font-bold text-[#202020]"
                  style={{ fontFamily: "DM Sans, sans-serif" }}
                >
                  {data.name}
                </h1>
                <h2 className="text-sm text-[#5B5A5A]">{data.role}</h2>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientsTestimonials;
