import React from 'react'

import bg from '../../Assets/Tour/recordsBg.png'
import logo from '../../Assets/Tour/clients.png'
import logo1 from '../../Assets/Tour/places.png'
import logo2 from '../../Assets/Tour/states.png'
import logo3 from '../../Assets/Tour/review.png'
import { useIntersectionObserver } from '../../Assets/CustomHook/CustomHook'
import CountUp from "react-countup";
const OurRecords = () => {
    const [ref, isVisible] = useIntersectionObserver({
        threshold: 0.1,
        triggerOnce: true,
      });
    return (
        <>
            <div className="py-14 md:px-16 px-6 my-4 md:my-8 overflow-hidden" style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
            }}>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 items-center text-[#0E2A46] font-bold" ref={ref}>
                    <div className="flex items-center md:flex-row flex-col justify-center space-x-5 " data-aos="fade-up">
                        <img src={logo} alt='img' className='mb-3'/>
                        <div className='text-center md:text-left'>
                            <div className="text-4xl font-bold">  {isVisible && <CountUp start={0} end={500} duration={5} />}+</div>
                            <div className="text-md ">Satisfied Clients</div>
                        </div>
                    </div>
                    <div className="flex items-center md:flex-row flex-col justify-center space-x-5" data-aos="fade-down ">
                        <img src={logo1} alt='img' className='mb-3'/>
                        <div className='text-center md:text-left'>
                            <div className="text-4xl font-bold">{isVisible && <CountUp start={0} end={1000} duration={5} />}+</div>
                            <div className="text-md">Places Visited</div>
                        </div>
                    </div>
                    <div className="flex items-center md:flex-row flex-col justify-center space-x-5" data-aos="fade-right">
                        <img src={logo2} alt='img' className='mb-3'/>
                        <div className='text-center md:text-left'>
                            <div className="text-4xl font-bold">{isVisible && <CountUp start={0} end={15} duration={5} />}+</div>
                            <div className="text-md">States Visited</div>
                        </div>
                    </div>
                    <div className="flex items-center md:flex-row flex-col justify-center space-x-5" data-aos="fade-left">
                        <img src={logo3} alt='img' className='mb-3'/>
                        <div className='text-center md:text-left'>
                            <div className="text-4xl font-bold">{isVisible && <CountUp start={0} end={100} duration={5} />}%</div>
                            <div className="text-md">Positive Reviews</div>
                        </div>
                    </div>
                </div>
            </div>

         
        </>
    )
}

export default OurRecords
