import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import logo from "../../Assets/Logo/logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { HiArrowRight } from "react-icons/hi";
import Form from "./Form";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const getLinkClass = (path) => {
    return location.pathname === path
      ? "text-base font-normal text-[#FCC101] cursor-pointer tracking-wide"
      : "text-base font-normal hover:text-[#FCC101] cursor-pointer tracking-wide";
  };
  const [open, setOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <nav className="hidden md:block bg-[#0E5A00] text-white px-6 md:px-16 py-2">
        <p className="mx-auto flex items-center justify-center space-x-7 text-center">
          <i>For Tour Booking Call Us On</i>
          <HiArrowRight />
          <i>+91 824 968 6752</i>
        </p>
      </nav>
      {/* Navbar */}
      <nav className="bg-[#fffefc] text-[#26232A] px-6 md:px-16 py-2 shadow-2xl">
        <div className="container mx-auto flex items-center justify-between">
          {/* 1st Section - Logo */}
          <div>
            <img src={logo} alt="baisaly-logo" className="w-auto h-14" />
          </div>

          {/* Middle Section - Page Links (hidden on small screens) */}
          <div className="hidden md:flex  space-x-8">
            <div onClick={() => navigate("/")} className={getLinkClass("/")}>
              Home
            </div>

            <div
              onClick={() => navigate("/about")}
              className={getLinkClass("/about")}
            >
              About Us
            </div>
            <div
              onClick={() => navigate("/tour")}
              className={getLinkClass("/tour")}
            >
              Tour
            </div>
            <div
              onClick={() => navigate("/gallery")}
              className={getLinkClass("/gallery")}
            >
              Gallery
            </div>
            <div
              onClick={() => navigate("/Contact")}
              className={getLinkClass("/Contact")}
            >
              Contact Us
            </div>
          </div>

          {/* 3rd Section - Login Button */}
          <div className="hidden md:block">
            <button
              className="bg-[#FBA710] hover:bg-[#de7a01] text-white px-6 py-3 font-medium rounded shadow-xl "
              onClick={handleAddOpen}
            >
              Enquiry Now
            </button>
          </div>
          {open && <Form onClose={handleModalClose} />}
          {/* Hamburger Menu for Small Screens */}
          <div className="md:hidden">
            <FaBars
              onClick={toggleSidebar}
              size={24}
              className="cursor-pointer"
            />
          </div>
        </div>
      </nav>

      {/* Sidebar for Small Screens */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50">
          <div className="fixed top-0 left-0 w-64 h-full bg-white shadow-md">
            <div className="p-4">
              <button
                className="text-black text-lg font-bold mb-8 float-right"
                onClick={toggleSidebar}
              >
                <IoClose size={25} className="text-[#064585]" />
              </button>

              <ul className="space-y-4 text-lg">
                <li
                  onClick={() => {
                    navigate("/");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/")}
                >
                  Home
                </li>
                <li
                  onClick={() => {
                    navigate("/about");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/about")}
                >
                  About Us
                </li>
                <li
                  onClick={() => {
                    navigate("/tour");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/tour")}
                >
                  Tour
                </li>
                <li
                  onClick={() => {
                    navigate("/gallery");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/gallery")}
                >
                  Gallery
                </li>
                <li
                  onClick={() => {
                    navigate("/contact");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/contact")}
                >
                  Contact
                </li>
              </ul>
              <div className=" md:hidden">
                <button
                  className="bg-[#FBA710] hover:bg-[#de7a01] text-white px-6 py-3 font-medium rounded shadow-xl "
                  onClick={() => {
                    handleAddOpen();
                    toggleSidebar();
                  }}
                >
                  Enquiry Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
