import React, { useState } from "react";
import Chilika from "../../Assets/Tour/Chilika.png";
import duration from "../../Assets/TourDetails/duration.png";
import maxPeople from "../../Assets/TourDetails/maxPeople.png";
import minAge from "../../Assets/TourDetails/minAge.png";
import pickUp from "../../Assets/TourDetails/pickUp.png";
import Map from "../../Components/Contact/Map";
import avatar from "../../Assets/TourDetails/avatar.jpg";
import star from "../../Assets/About/Stars.png";
import name from "../../Assets/Contact/name.png";
import email from "../../Assets/Contact/formEmail.png";
import phone from "../../Assets/Contact/phone.png";
import rating from "../../Assets/TourDetails/rating.png";
import message from "../../Assets/Contact/message.png";
import date from "../../Assets/TourDetails/date.png";
import { FaPlaneDeparture } from "react-icons/fa6";



const detailButtons = [
  { id: "description", label: "DESCRIPTION" },
  { id: "program", label: "PROGRAM" },
  { id: "review", label: "REVIEW" },
  { id: "map", label: "MAP" },
];

const tourDetails = [
  { icon: duration, title: "Duration", value: "9 days" },
  { icon: maxPeople, title: "Max People", value: "20" },
  { icon: minAge, title: "Min Age", value: "12+" },
  { icon: pickUp, title: "Pick Up", value: "Airport" },
];



const DetailSection = ({tripData}) => {
  const [details, setDetails] = useState("description");
 console.log(tripData)
  return (
    <div className="mx-6 md:mx-16 my-4 md:my-8 flex flex-col lg:flex-row gap-16">
      <div className="w-full lg:w-[60%]" data-aos="fade-right">
        <h1
          className="text-3xl md:text-4xl font-bold text-[#292929] tracking-wide mb-4"
          style={{ fontFamily: "DM Sans, sans-serif" }}
        >
          {tripData.title}
        </h1>
        <img src={tripData.imageUrl} alt="Chilika Lake" className="w-full mb-8 rounded" />

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 mb-8 ">
          {tourDetails.map((detail) => (
            <div
              key={detail.title}
              className="flex justify-start items-center gap-2"
            >
              <img
                src={detail.icon}
                alt={`${detail.title} icon`}
                className="w-[40%]"
              />
              <div
                className="flex flex-col"
                style={{ fontFamily: "DM Sans, sans-serif" }}
              >
                <p className="text-sm font-bold text-slate-900">
                  {detail.title}
                </p>
                <p className="text-sm text-gray-700 font-semibold">
                  {detail.value}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="  grid grid-cols-1 md:grid-cols-4">
          {detailButtons.map((button) => (
            <button
              key={button.id}
              className={`font-bold text-md md:text-lg p-4 text-left md:text-center ${
                details === button.id
                  ? "bg-[#0E5A00] text-white"
                  : "hover:text-[#0E5A00]"
                  ? "border-b-0"
                  : "border-b-2"
              } transition-all`}
              style={{ fontFamily: "DM Sans, sans-serif" }}
              onClick={() => setDetails(button.id)}
            >
              {button.label}
            </button>
          ))}
        </div>

        <div className="p-2 md:p-8 border ">
          {details === "description" && (
            <p className="leading-relaxed text-base">
             {tripData.content.description}
            </p>
          )}

          {details === "program" && (
            <div className="p-4">
              <h1
                className="text-xl font-bold text-[#292929] tracking-wide mb-4"
                style={{ fontFamily: "DM Sans, sans-serif" }}
              >
                Program <span className="font-light text-base">( {tripData.content.program.days.length} days )</span>
              </h1>
              <p className="mb-4 text-base">
              {tripData.content.program.summary}
              </p>
              {tripData.content.program.days.map((day) => (
                <div
                  key={day.day}
                  className="flex flex-col md:flex-row relative md:items-center justify-between gap-4 hover:bg-[#FFFDE8] p-0 md:p-4 rounded-full mb-4"
                >
                  <span className="bg-[#0E5A00] w-16 h-16 p-4 text-center rounded-full text-white font-bold">
                    Day <span className="font-bold">{day.day}</span>
                  </span>
                  <div className="flex flex-col items-start">
                    <h1 className="text-xl font-bold text-[#292929] tracking-wide mb-2">
                      {day.title}
                    </h1>
                    <p className="leading-relaxed tracking-wide text-base">{day.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {details === "review" && (
            <div>
              <div className="flex flex-col md:flex-row p-8 bg-slate-50 items-center gap-4 border-2 group hover:bg-[#d7ffbb] mb-8 transition all duration-300 ease-in-out">
                <p className="w-24 h-24 p-8 text-center rounded-full bg-[#d7ffbb] font-bold text-2xl group-hover:bg-white transition all duration-300 ease-in-out">
                  {tripData.content.review.rating}
                </p>
                <div className="flex flex-col">
                  <h1
                    className="text-2xl font-bold text-[#292929] tracking-wide mb-4"
                    style={{ fontFamily: "DM Sans, sans-serif" }}
                  >
                    Excellent{" "}
                   
                  </h1>
                  <p className="text-base leading-relaxed tracking-wide">
                    {tripData.content.review.ratingDescription}
                  </p>
                </div>
              </div>

              <h1
                className="text-2xl font-bold text-[#292929] tracking-wide mb-4"
                style={{ fontFamily: "DM Sans, sans-serif" }}
              >
                {tripData.content.review.reviews.length} Reviews
              </h1>

              {tripData.content.review.reviews.map((review, index) => (
                <div
                  key={index}
                  className="flex p-2 gap-2 md:gap-4 relative mb-8"
                >
                  <img
                    src={review.reviewerImage}
                    alt="Reviewer"
                    className="rounded-full absolute w-12"
                  />
                  <div className="flex flex-col gap-2 ">
                    <div className="border-2 ml-16 md:ml-20 p-4">
                      <h1>{review.reviewerName}</h1>
                      <img src={star} alt="Rating" className="w-20 mb-4" />
                      <p className="mb-4">{review.reviewText}</p>
                      <button className="bg-[#0E5A00] hover:bg-[#033b00] px-4 text-sm text-white">
                        {review.replyText}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <form>
                <h1
                  className="text-2xl font-bold text-[#292929] tracking-wide mb-4"
                  style={{ fontFamily: "DM Sans, sans-serif" }}
                >
                  Leave a Review
                </h1>
                <div className="mb-8 flex border border-[#949292] border-opacity-50">
                  <img src={name} alt="" className="p-4 bg-white w-14" />
                  <input
                    className="w-full p-2 pl-8 border border-l-[#949292] border-opacity-50 outline-none opacity-80 "
                    type="text"
                    id="name"
                    placeholder="Name"
                  />
                </div>
                <div className="mb-8 flex border border-[#949292] border-opacity-50">
                  <img src={email} alt="" className="p-4 bg-white w-14" />
                  <input
                    className="w-full p-2 pl-8 border border-l-[#949292] border-opacity-50 outline-none opacity-80 "
                    type="email"
                    id="email"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-8 flex border border-[#949292] border-opacity-50">
                  <img src={phone} alt="" className="p-4 bg-white w-14" />
                  <input
                    className="w-full p-2 pl-8 border border-l-[#949292] border-opacity-50 outline-none opacity-80 "
                    type="text"
                    id="phone"
                    placeholder="Phone"
                  />
                </div>
                <div className="mb-8 flex border border-[#949292] border-opacity-50">
                  <img src={message} alt="" className="p-4 bg-white w-14" />
                  <input
                    className="w-full p-2 pl-8 border border-l-[#949292] border-opacity-50 outline-none opacity-80 "
                    id="message"
                    placeholder="Your Review"
                  ></input>
                </div>

                <button
                  type="submit"
                  className="bg-[#0E5A00] hover:bg-[#033b00] text-white px-6 py-3 font-medium rounded shadow-xl w-full md:w-1/3 xl:w-1/4"
                >
                  Submit
                </button>
              </form>
            </div>
          )}

          {details === "map" && <Map location={tripData.content.mapUrl} className="w-full" />}
        </div>
      </div>

      <div className="w-full lg:w-[40%] lg:mt-24">
        <div className="bg-[#FBA710] text-white p-2 md:p-4 text-center flex flex-col mb-8 items-center "data-aos="flip-up">
          <h1
            className="text-3xl md:text-4xl font-bold tracking-wide   text-white text-center "
            style={{ fontFamily: "DM Sans, sans-serif" }}
          >
            ₹649 <span className="font-light text-sm">/ per person</span>
          </h1>
          <img src={rating} alt="" className="" />
        </div>

        <form data-aos="zoom-in-up  ">
          <h1
            className="text-2xl md:text-3xl  font-bold p-2 tracking-wide mb-4 lg:mb-8 bg-[#0E5A00] text-white text-center"
            style={{ fontFamily: "DM Sans, sans-serif" }}
          >
            BOOKING
          </h1>
          <div className="mb-8 flex border border-[#949292]">
            <img src={name} alt="" className="p-4 bg-white w-14" />
            <input
              className="w-full p-2 pl-8 border border-l-[#949292] outline-none opacity-80 "
              type="text"
              id="name"
              placeholder="Name"
            />
          </div>
          <div className="mb-8 flex border border-[#949292]">
            <img src={email} alt="" className="p-4 bg-white w-14" />
            <input
              className="w-full p-2 pl-8 border border-l-[#949292] outline-none opacity-80 "
              type="email"
              id="email"
              placeholder="Email"
            />
          </div>
          <div className="mb-8 flex border border-[#949292]">
            <img src={phone} alt="" className="p-4 bg-white w-14" />
            <input
              className="w-full p-2 pl-8 border border-l-[#949292] outline-none opacity-80 "
              type="text"
              id="phone"
              placeholder="Phone"
            />
          </div>

          <div className="mb-8 flex border border-[#949292]">
            <img src={date} alt="" className="p-4 bg-white w-14 opacity-80" />
            <input
              className="w-full p-2 pl-8 border border-l-[#949292] outline-none opacity-80"
              type="date"
              id="date"
              placeholder="Date"
            />
          </div>
          <div className="mb-8 flex border border-[#949292] items-center">
            <div className=""><FaPlaneDeparture className=" w-14 opacity-80"/></div>
            <input
              className="w-full p-4 pl-8 border border-l-[#949292] outline-none opacity-80 "
              type="text"
              id="city"
              placeholder="Departure City"
            />
          </div>

          <button
            type="submit"
            className="bg-[#0E5A00] hover:bg-[#033b00] text-white px-6 py-3 font-medium rounded shadow-xl w-full md:w-1/3 xl:w-1/4 mt-8"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default DetailSection;
