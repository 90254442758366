import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import nameIcon from "../../Assets/Contact/name.png";
import emailIcon from "../../Assets/Contact/formEmail.png";
import phoneIcon from "../../Assets/Contact/phone.png";
import messageIcon from "../../Assets/Contact/message.png";
import departureCityIcon from "../../Assets/Contact/departureCity.png";
import calendarIcon from "../../Assets/Contact/calender.png";
import childrenIcon from "../../Assets/Contact/children.png";
import adultIcon from "../../Assets/Contact/adult.png";
import { IoClose } from "react-icons/io5";

const Form = ({ onClose }) => {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    departureCity: "",
    date: "",
    children: "",
    adults: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  


  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = "Name is required";
    if (!formValues.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formValues.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formValues.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formValues.message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);

      // Consolidating all form data into one object
      const formattedData = {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
        message: formValues.message,
        departureCity: formValues.departureCity,
        date: formValues.date,
        children: formValues.children,
        adults: formValues.adults,
      };

      console.log("Formatted Data:", formattedData);

      // Sending email with EmailJS
      emailjs
        .send(
          "service_qda9r1p",
          "template_woapl0d",
          formattedData, // Send the consolidated object
          "NcGgN4drK0cL1rQnO"
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccessMessage("Message sent successfully!");
          setFormValues({
            name: "",
            email: "",
            phone: "",
            message: "",
            departureCity: "",
            date: "",
            children: "",
            adults: "",
          });
          setErrors({});
        })
        .catch((error) => {
          console.error("FAILED...", error);
          setErrors({ form: "Failed to send message, please try again." });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

 

  return (
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50 "
      ref={modalRef}
      onClick={closeModal}
    >
      <div className="lg:w-[50%] bg-orange-50 p-6 md:px-24 rounded-md relative">
      <button
            onClick={onClose}
            type="submit"
            className="text-xl text-[#FBA710] absolute top-4 right-4"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <IoClose />
          </button>
        <h2 className="text-2xl sm:text-3xl font-bold mb-6">Get In Touch</h2>
        {successMessage && (
          <p className="text-green-500 text-sm mb-2">{successMessage}</p>
        )}
        {errors.form && (
          <p className="text-red-500 text-sm mb-2">{errors.form}</p>
        )}
        <form onSubmit={handleSubmit}>
          {/* Name Input */}
          <div className="mb-4 flex border border-gray-400">
            <img src={nameIcon} alt="" className="p-2 bg-white w-10" />
            <input
              className="w-full p-2 pl-8 border-l-gray-400 outline-none"
              type="text"
              id="name"
              placeholder="Name"
              value={formValues.name}
              onChange={handleChange}
            />
          </div>
          {/* Email Input */}
          <div className="mb-4 flex border border-gray-400">
            <img src={emailIcon} alt="" className="p-2 bg-white w-10" />
            <input
              className="w-full p-2 pl-8 border-l-gray-400 outline-none"
              type="email"
              id="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />
          </div>
          {/* Phone Input */}
          <div className="mb-4 flex border border-gray-400">
            <img src={phoneIcon} alt="" className="p-2 bg-white w-10" />
            <input
              className="w-full p-2 pl-8 border-l-gray-400 outline-none"
              type="text"
              id="phone"
              placeholder="Phone"
              value={formValues.phone}
              onChange={handleChange}
            />
          </div>
          {/* Message Input */}
          <div className="mb-4 flex border border-gray-400">
            <img src={messageIcon} alt="" className="p-2 bg-white w-10" />
            <input
              className="w-full p-2 pl-8 border-l-gray-400 outline-none"
              type="text"
              id="message"
              placeholder="Message"
              value={formValues.message}
              onChange={handleChange}
            />
          </div>
          {/* Other Inputs */}
          <div className="mb-4 flex border border-gray-400">
            <img src={departureCityIcon} alt="" className="p-2 bg-white w-10" />
            <input
              className="w-full p-2 pl-8 border-l-gray-400 outline-none"
              type="text"
              id="departureCity"
              placeholder="Departure City"
              value={formValues.departureCity}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 flex border border-gray-400">
            <img src={calendarIcon} alt="" className="p-2 bg-white w-10" />
            <input
              className="w-full p-2 pl-8 border-l-gray-400 outline-none"
              type="date"
              id="date"
              value={formValues.date}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 flex border border-gray-400">
            <img src={childrenIcon} alt="" className="p-2 bg-white w-10" />
            <input
              className="w-full p-2 pl-8 border-l-gray-400 outline-none"
              type="text"
              id="children"
              placeholder="No. of Children"
              value={formValues.children}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 flex border border-gray-400">
            <img src={adultIcon} alt="" className="p-2 bg-white w-10" />
            <input
              className="w-full p-2 pl-8 border-l-gray-400 outline-none"
              type="text"
              id="adults"
              placeholder="No. of Adults"
              value={formValues.adults}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-[#FBA710] hover:bg-[#de7a01] text-white px-6 py-3 font-medium rounded shadow-xl w-36"
          >
            {isSubmitting ? "Sending..." : "Send Message"}
          </button>

          
        </form>
      </div>
    </div>
  );
};

export default Form;
