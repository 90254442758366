import React from 'react'


const Map = ({location}) => {
    return (
        <React.Fragment>
            <div>
                <div className=" h-80 lg:h-[500px] mx-6 md:mx-16 my-4 md:my-8 ">
                    <iframe
                        src={location}                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    />
                </div>
            </div>
           
        </React.Fragment>
    )
}

export default Map
