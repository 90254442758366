import React from "react";
import car1 from "../../Assets/Tour/car1.png";
import car2 from "../../Assets/Tour/car2.png";
import car3 from "../../Assets/Tour/car3.png";
import passenger from "../../Assets/Tour/passenger.png";
import gearShifting from "../../Assets/Tour/gearShifting.png";
import luggage from "../../Assets/Tour/luggages.png";
import door from "../../Assets/Tour/door.png";

const data = [
  {
    id: 1,
    img: car1,
    carName: "Toyota Innova",
    passengerCapacity: 7,
    gearType: "Manual",
    luggageCapacity: 4,
    noOfDoors: 4,
    aos: "fade-right"
  },

  {
    id: 2,
    img: car2,
    carName: "Toyota Innova",
    passengerCapacity: 7,
    gearType: "Manual",
    luggageCapacity: 4,
    noOfDoors: 4,
    aos: "flip-up"
  },

  {
    id: 3,
    img: car3,
    carName: "Force Motors",
    passengerCapacity: 9,
    gearType: "Manual",
    luggageCapacity: 8,
    noOfDoors: 4,
    aos: "fade-left"
  },
];

function VehicleService() {
  return (
    <div className="mx-6 md:mx-16 my-4 md:my-8 flex flex-col items-center">
      <h1
        className="text-3xl md:text-4xl font-bold text-center text-[#292929] tracking-wide mb-4"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        Vehicle Service
      </h1>
      <p
        className="w-full lg:w-[50%] text-center text-[#666666] capitalize mb-4"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        We are providing vehicle service with a experienced driver and a guide,
        who can help you and can guide you with your every step
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center justify-between gap-8">
        {data.map((data) => (
          <div
            key={data.id}
            className="flex flex-col"
            style={{ fontFamily: "DM Sans, sans-serif" }}
            data-aos={data.aos}
          >
            <img src={data.img} alt={data.carName} className="mb-4" />
            <h1 className="font-bold text-2xl mb-4">{data.carName}</h1>
            <div className="flex gap-8">
              <div className="flex flex-col items-center gap-2">
                <img src={passenger} alt="Passenger Capacity" />
                <p className="text-[#555555] text-sm">
                  {data.passengerCapacity}
                </p>
              </div>
              <div className="flex flex-col items-center gap-2">
                <img src={gearShifting} alt="Gear Type" />
                <p className="text-[#555555] text-sm">{data.gearType}</p>
              </div>

              <div className="flex flex-col items-center gap-2">
                <img src={luggage} alt="Luggage Capacity" />
                <p className="text-[#555555] text-sm">{data.luggageCapacity}</p>
              </div>

              <div className="flex flex-col items-center gap-2">
                <img src={door} alt="No. of Doors" />
                <p className="text-[#555555] text-sm">{data.noOfDoors}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VehicleService;
