import React from "react";
import Navbar from "../../Components/Layout/Navbar";
import Footer from "../../Components/Layout/Footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const NonAuthLayout = (props) => {
  const location = useLocation();
  useEffect(() => {
    console.log("I am running");
    window.scrollTo(600, 0);
    AOS.init({
      offset: 100,
      duration: 1000,
      easing: "ease-in-out",
      delay: 50,


    });

  }, [location.pathname]);
  return (
    <React.Fragment>
      <Navbar />
      {props.children}
      <Footer />
    </React.Fragment>
  );
};

export default NonAuthLayout;
