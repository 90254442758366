import React from 'react'
import SimpleBanner from '../Common/SimpleBanner';
import DetailSection from '../../Components/TourDetails/DetailiSection';
import ClientsTestimonials from '../../Components/About Us/ClientsTestimonials';
import YouMayLike from '../../Components/TourDetails/YouMayLike';
import Newsletter from '../../Components/home/Newsletter';
import Aboutbanar from '../../Assets/TourDetails/topbanner.png'
import JagannathTemple from '../../Assets/Tour/JagannathTempleTour.png'
import Rabi from '../../Assets/TourDetails/rabi.jpg';
import Jeeban from '../../Assets/homeimgs/jeeban.jpg'

const JagannathTempleTour = () => {
  const tripData = {
    title: "Discover the Spiritual Significance of Jagannath Temple",
    imageUrl: JagannathTemple, // Replace with the actual image path
    details: [
      { icon: "calendar_icon", label: "Duration", value: "1 day" },
      { icon: "people_icon", label: "Max People", value: "100" },
      { icon: "age_icon", label: "Min Age", value: "10+" },
      { icon: "location_icon", label: "Pick Up", value: "Puri Railway Station" }
    ],

    content: {
      description: "The Jagannath Temple in Puri is a sacred pilgrimage site dedicated to Lord Jagannath. Known for its majestic architecture and the annual Rath Yatra, this temple offers visitors a glimpse into Odisha's rich cultural and spiritual heritage.",
      program: {
        summary: "This one-day visit to the Jagannath Temple will take you through its profound spiritual significance, unique rituals, and the history of Lord Jagannath's cult. The tour includes a visit to the temple and participation in the vibrant festivities if available.",
        days: [
          {
            day: 1,
            title: "Jagannath Temple Visit",
            description: "Start your day with a visit to the Jagannath Temple, marvel at the intricate carvings, and experience the vibrant rituals performed for Lord Jagannath. If visiting during the Rath Yatra, enjoy the sight of the grand procession with thousands of devotees."
          }
        ]
      },
      review: {
        rating: 4.8,
        ratingText: "Spiritual & Majestic",
        ratingDescription: "Jagannath Temple in Puri is a sacred and majestic site, known for its rich history, spiritual significance, and vibrant atmosphere.",
        reviewCount: 50,
        summary: "Jagannath Temple is one of the Char Dham pilgrimage sites and a must-visit for those seeking spiritual awakening. The grandeur of the temple and its rich cultural significance leave a lasting impression.",
        reviews: [
          {
            reviewerName: "Rabi Samantray",
            reviewerImage: Rabi, // Replace with the actual image path
            rating: 5,
            reviewText: "A truly divine experience. The temple is majestic, and the rituals are deeply spiritual. Witnessing the Rath Yatra was an unforgettable experience.",
            replyText: "Reply"
          },
          {
            reviewerName: "Jeeban Achrya",
            reviewerImage: Jeeban, // Replace with the actual image path
            rating: 4,
            reviewText: "A peaceful and enriching visit. The atmosphere inside the temple is electric with devotion. A must-see for spiritual seekers.",
            replyText: "Reply"
          }
        ]
      },
      mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15015.323891023794!2d85.81038409483234!3d19.804762704888024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19c6b8bfe386af%3A0x8f052c84639c7d48!2sShree%20Jagannatha%20Temple%20Puri!5e0!3m2!1sen!2sin!4v1731676063801!5m2!1sen!2sin"
    }
  }
  return (
    <div>
      <SimpleBanner
        image={Aboutbanar}
        name="Tour Details"
        path="Home / Tour Details"
      />
      <DetailSection tripData={tripData} />
      <ClientsTestimonials />
      <YouMayLike />
      <Newsletter />
    </div>
  )
}

export default JagannathTempleTour