import React from "react";
import SimpleBanner from "../Common/SimpleBanner";
import Aboutbanar from "../../Assets/About/aboutbg.png";
import OurTeam from "../../Components/About Us/OurTeam";
import TheWandererGuide from "../../Components/About Us/TheWandererGuide";
import VisionMission from "../../Components/About Us/VisionMission";
import WhyChooseUs from "../../Components/About Us/WhyChooseUs";
import ClientsTestimonials from "../../Components/About Us/ClientsTestimonials";
import VehicleServices from '../../Components/Tour/VehicleService'
import Newsletter from "../../Components/home/Newsletter";

const About = () => {
  return (
    <div className="overflow-x-hidden">
      <SimpleBanner image={Aboutbanar} name="About Us" path="Home / About Us" />
      <TheWandererGuide />
      <WhyChooseUs />
      <VisionMission />
      <ClientsTestimonials />
      <VehicleServices />
      <Newsletter />
    </div>
  );
};

export default About;
