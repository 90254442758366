import React from "react";
import NotFound from "../Screens/Common/NotFound";
import Home from "../Screens/User/Home";
import About from "../Screens/User/About";
import Contact from "../Screens/User/Contact";
import Tour from "../Screens/User/Tour";
import Gallery from "../Screens/User/Gallery";
import TourDetails from "../Screens/User/TourDetails";
import GopalpurTour from "../Screens/User/GopalpurTour";
import DhauliGiriToure from "../Screens/User/DhauliGiriToure";
import JagannathTempleTour from "../Screens/User/JagannathTempleTour";
import RamMandirTour from "../Screens/User/RamMandirTour";
import IskconTempleTour from "../Screens/User/IskconTempleTour";
import ChilikaTour from "../Screens/User/ChilikaTour";

const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/about", component: <About /> },
  { path: "/contact", component: <Contact /> },
  { path: "/tour", component: <Tour /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/tourDetails", component: <TourDetails /> },
  { path: "/tourDetails/gopalpurTour", component: <GopalpurTour /> },
  { path: "/tourDetails/dhauliGiriTour", component: <DhauliGiriToure /> },
  { path: "/tourDetails/jagannathTempleTour", component: <JagannathTempleTour /> },
  { path: "/tourDetails/ramMandirTour", component: <RamMandirTour /> },
  { path: "/tourDetails/iskconTempleTour", component: <IskconTempleTour /> },
  { path: "/tourDetails/chilikaTour", component: <ChilikaTour /> },
  { path: "*", component: <NotFound /> },
];

export { userRoutes };
