import React from 'react'
import HeroSEction from '../../Components/home/HeroSEction'
import TopDestinations from '../../Components/Tour/TopDestinations'
import OurRecords from '../../Components/Tour/OurRecords'
import VehicleService from '../../Components/Tour/VehicleService'
import TrendingTours from '../../Components/home/TrendingTours'
import Newsletter from '../../Components/home/Newsletter'
import WhyChooseUs from '../../Components/About Us/WhyChooseUs'
import ClientsTestimonials from '../../Components/About Us/ClientsTestimonials'

const Home = () => {
  return (
    <div className='overflow-hidden'>
      <HeroSEction/>
      <WhyChooseUs/>
      <TrendingTours/>
      <TopDestinations/>
      <OurRecords/>
      <ClientsTestimonials />
      <VehicleService/>
      <Newsletter/>
    </div>
  )
}

export default Home
