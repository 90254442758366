import React from 'react'
// import image from '../../Assets/footerbg.png'
import { FaFacebookF, FaInstagram, FaPinterestSquare, FaYoutubeSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Logo/logo.svg'
import img1 from '../../Assets/Logo/location.png'
import img2 from '../../Assets/Logo/message.png'
import img3 from '../../Assets/Logo/call.png'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <footer className="footer-bg bg-cover bg-center">
                <div className="px-4 py-10  bg-[#F4FFFD]">
                    <div className="grid md:grid-cols-[1.5fr_2fr_1fr] lg:grid-cols-[1fr_2fr_1fr] grid-cols-1 gap-10 w-[90%] mx-auto">
                        <div className="md:ml-11">
                            <h2 className="text-lg font-bold mb-4 text-[#0E5A00]">QUICK LINKS</h2>
                            <ul className='text-[#FBA710]'>
                                <li onClick={() => { navigate('/'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Home</li>
                                <li onClick={() => { navigate('/about'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">About Us</li>
                                <li onClick={() => { navigate('/tour'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Tour</li>
                                <li onClick={() => { navigate('/gallery'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Gallery</li>
                                <li onClick={() => { navigate('/contact'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Contact</li>
                            </ul>
                        </div>
                        <div className="text-center lg:mr-20 xl:mr-32 text-[#FBA710] flex flex-col items-start md:items-center">
                            <img src={logo} alt="logo" className='w-36 md:w-fit lg:w-36' />
                            <p className="mb-4 text-start md:text-center mt-4">At Go Cabz we’re passionate about crafting memorable travel experiences tailored to your interests. From adventurous treks to serene getaways, we’re here to help you explore the world with ease and excitement.</p>
                            <div className="flex justify-center space-x-4 text-[#0E5A00]">
                                <div>
                                    <a href='#' target="_blank" rel="noopener noreferrer" >
                                        <FaFacebookF /></a>
                                </div>
                                <div>
                                    <a href="#" target="_blank" rel="noopener noreferrer">
                                        <FaInstagram />
                                    </a>
                                </div>
                                <FaTwitter />
                                <FaYoutubeSquare />
                                <FaPinterestSquare />
                            </div>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold mb-4 text-[#0E5A00]">CONTACT US</h2>
                            <p className="mb-2 text-[#FBA710] flex items-center "><img src={img1} alt='img' className='fa fa-map-marker fa-lg mr-4 text-[#0E5A00]'></img>Gpo, Plot No 298, Unit-6, Biju Patnaik International Airport-Aerodrome, Ekamra Rd, Ganga Nagar, Bhubaneswar, Odisha 751020</p>
                            <p className="mb-2 text-[#FBA710] flex items-center "><img src={img2} alt='img' className="fa fa-envelope fa-lg mr-4 text-[#0E5A00]"></img>rameshbarad264@gmail.com</p>
                            <p className="mb-2 text-[#FBA710] flex items-center "><img src={img3} alt='img' className="fa fa-phone fa-lg text-[#0E5A00] mr-4"></img>+91 824 968 6752</p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="py-4 bg-[#154604] text-[#F1F1F1]">
                <div className="md:mx-16 px-4 flex flex-wrap justify-between items-center">
                    <p className="w-full sm:w-auto text-center sm:text-left">© {currentYear} GO CABZ, Bhubaneswar</p>
                    <p className="w-full sm:w-auto text-center sm:text-right">
                        Design & Developed by{' '}
                        <a
                            href="https://www.webbocket.com/"
                            target="_blank"
                            rel="noopener noreferrer"                        >
                            WEB_BOCKET
                        </a>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footer;