import React from "react";
import wonders from "../../Assets/About/wonders.png";
import { useIntersectionObserver } from "../../Assets/CustomHook/CustomHook";
import CountUp from "react-countup";
import logo from "../../Assets/Tour/clients.png";
import logo1 from "../../Assets/Tour/places.png";
import logo2 from "../../Assets/Tour/states.png";
import logo3 from "../../Assets/Tour/review.png";

function TheWandererGuide() {
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <div className="mx-6 md:mx-16 my-6 md:my-16 flex flex-col lg:flex-row items-center gap-8">
      <img
        src={wonders}
        alt="The image containing reknowned monuments of the world"
        className="w-full lg:w-[50%]"
        data-aos="fade-down"
      />
      <div className="w-full lg:w-[50%]"data-aos="fade-left">
        <p className="text-[#FCC101] font-semibold capitalize mb-2">
          We are Trusted
        </p>
        <h1
          className="text-3xl md:text-4xl font-bold  text-[#292929] tracking-wide mb-4 leading-snug lg:leading-snug"
          style={{ fontFamily: "DM Sans, sans-serif" }}
        >
          The Wanderer's Guide: <br />
          Expert Travel Planning
        </h1>
        <p className="w-full capitalize text-[#767676] tracking-wide leading-relaxed text-base mb-8">
          We Go-Cabz, we take pride in being a trusted travel agency in Odisha,
          with over 14 years of experience in the industry. Since our inception
          on March 4, 2007, we have been committed to offering seamless and
          memorable travel experiences to our clients. Our expertise in
          providing safe, reliable, and affordable transportation services has
          earned us a reputation for excellence. Whether you’re traveling for
          business or leisure, we offer a range of services tailored to your
          needs, from taxi rentals to guided tours. Our team of experienced
          professionals ensures that your journey is comfortable and
          stress-free.
          <br /> <br /> 
          At We Go-Cabz, we believe in building long-term
          relationships with our clients, offering personalized solutions and
          exceptional customer service. Trust us to make every trip a smooth and
          enjoyable experience.
          <br /> <br />
          Don’t miss out on the journey of a lifetime. Book with us today and
          get ready for an adventure that will leave you breathless!
        </p>
        <div
          className="grid lg:grid-cols-4 grid-cols-2  gap-3 items-center font-bold"
          ref={ref}
        >
          <div
            className="flex items-center md:flex-row flex-col lg:border-r-2 border-[#FCC101]"
            data-aos="fade-up"
          >
            <div className="text-center md:text-left ">
              <div className="text-2xl text-[#FCC101] font-bold">
                {" "}
                {isVisible && <CountUp start={0} end={500} duration={5} />}+
              </div>
              <div className="text-md text-[#616161]">Satisfied Clients</div>
            </div>
          </div>
          <div
            className="flex items-center md:flex-row flex-col lg:border-r-2 border-[#FCC101]"
            data-aos="fade-down "
          >
            <div className="text-center md:text-left">
              <div className="text-2xl text-[#FCC101] font-bold">
                {isVisible && <CountUp start={0} end={1000} duration={5} />}+
              </div>
              <div className="text-md text-[#616161]">Places Visited</div>
            </div>
          </div>
          <div
            className="flex items-center md:flex-row flex-col lg:border-r-2 border-[#FCC101]"
            data-aos="fade-right"
          >
            <div className="text-center md:text-left">
              <div className="text-2xl text-[#FCC101] font-bold">
                {isVisible && <CountUp start={0} end={15} duration={5} />}+
              </div>
              <div className="text-md text-[#616161]">States Visited</div>
            </div>
          </div>
          <div
            className="flex items-center md:flex-row flex-col lg:border-r-2 border-[#FCC101]"
            data-aos="fade-left"
          >
            <div className="text-center md:text-left">
              <div className="text-2xl text-[#FCC101] font-bold">
                {isVisible && <CountUp start={0} end={100} duration={5} />}%
              </div>
              <div className="text-md text-[#616161]">Positive Reviews</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TheWandererGuide;
