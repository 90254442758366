import React from "react";
import Konark from "../../Assets/Tour/Konark.png";
import Chilika from "../../Assets/Tour/ChilikaDolphin.png";
import JagannathTemple from '../../Assets/Tour/JagannathTemple.png';
import LingarajTemple from '../../Assets/Tour/LingarajTemple.png';
import Dhauli from "../../Assets/Tour/Dhauli.png";
import Jiranga from "../../Assets/Tour/Jiranga.png";
import RamMandir from "../../Assets/Tour/RamMandirTour.png";


const data = [
  {
    id: 1,
    imgSrc: Konark,
    district: "Puri",
    name: "Konark Temple",
    aos: "flip-right",
  },

  {
    id: 2,
    imgSrc: Chilika,
    district: "Ganjam",
    name: "Chilika Lake",
    aos: "flip-right",
  },

  {
    id: 3,
    imgSrc: JagannathTemple,
    district: "Puri",
    name: "Jagannath Temple",
    aos: "flip-right",
    aos: "flip-right"
  },

  {
    id: 4,
    imgSrc: Dhauli,
    district: "Bhubaneswar",
    name: "Dhauli Giri",
    aos: "flip-right"
  },

  {
    id: 5,
    imgSrc: LingarajTemple,
    district: "Bhubaneswar",
    name: "Lingaraj Temple",
    aos: "flip-right"
  },

  {
    id: 6,
    imgSrc: Jiranga,
    district: "Gajapati",
    name: "Jiranga",
    aos: "flip-right"
  },
  
  {
    id: 7,
    imgSrc: RamMandir,
    district: "Bhubaneswar",
    name: "Ram Temple",
    aos: "flip-right"
  },
];

function TopDestinations() {
  return (
    <div className="m-6 md:mx-16 flex flex-col items-center">
      <h1
        className="text-3xl md:text-4xl font-bold text-center text-[#292929] tracking-wide mb-8"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        Top Destinations
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-7 text-center justify-between gap-2 md:gap-8">
        {data.map((data) => (
          <div
            key={data.id}
            className="flex flex-col items-center"
            style={{ fontFamily: "DM Sans, sans-serif" }}
            data-aos={data.aos}
          >
            <img
              src={data.imgSrc}
              alt={data.name}
              className=" mb-4 h-40 w-40 rounded-full "
            />
            <h2 className=" font-bold text-[#292929] text-xl">
              {data.name}
            </h2>
            <h1 className="font-semibold text-[#666666] text-base mb-8">{data.district}</h1>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopDestinations;
