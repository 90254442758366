import React from 'react'
import GalleryImg from '../../Components/Gallery/GalleryImg'
import SimpleBanner from '../Common/SimpleBanner'
import Aboutbanar from '../../Assets/Gallery/Gallerybannerimgs.png'
const Gallery = () => {
  return (
    <div className='overflow-hidden'>
      <SimpleBanner
        image={Aboutbanar}
        name="Our Gallery"
        path="Home / Our Gallery"
      />
      <GalleryImg/>
    </div>
  )
}

export default Gallery