import React from "react";
import Dhauligiri from "../../Assets/Tour/Dhauligiri.png";
import Gopalpur from "../../Assets/Tour/Gopalpur.png";
import JagannathTemple from "../../Assets/Tour/JagannathTempleTour.png";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    img: Dhauligiri,
    name: "Dhauligiri, Bhubaneswar",
    description:
      "We focus on unique, local experiences that allow you to immerse yourself in the culture, history, and natural beauty of the places you visit.",
    url: "/tourDetails/dhauliGiriTour",
    aos:"flip-left"
  },

  {
    id: 2,
    img: Gopalpur,
    name: "Gopalpur Sea Beach",
    description:
      "We focus on unique, local experiences that allow you to immerse yourself in the culture, history, and natural beauty of the places you visit.",
    url: "/tourDetails/gopalpurTour",
    aos:"zoom-in-up"
  },

  {
    id: 3,
    img: JagannathTemple,
    name: "Jagannath Temple, Puri",
    description:
      "We focus on unique, local experiences that allow you to immerse yourself in the culture, history, and natural beauty of the places you visit.",
    url: "/tourDetails/jagannathTempleTour",
    aos:"flip-right"
  },
];
function YouMayLike() {
  const navigate = useNavigate();

  return (
    <div className="mx-6 md:mx-16 my-4 md:my-16 flex flex-col">
      <h1
        className="text-3xl md:text-4xl font-bold text-[#292929] tracking-wide mb-4 text-center"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        You May Like
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {data.map((data) => (
          <div key={data.id} className="flex flex-col"data-aos={data.aos}>
            <img src={data.img} alt="" className="mb-4" />
            <h1
              className="mb-2 font-bold text-[#292929] text-xl"
              style={{ fontFamily: "DM Sans, sans-serif" }}
            >
              {data.name}
            </h1>
            <p
              className="text-[#767676] text-base leading-relaxed mb-4"
              style={{ fontFamily: "Lato, sans-serif" }}
            >
              {data.description}
            </p>

            <button
            onClick={()=>{navigate(`${data.url}`); window.scrollTo(600, 0);}}
              type="submit"
              className="bg-[#FBA710] hover:bg-[#de7a01] text-white px-6 py-3 font-medium rounded shadow-xl w-full lg:w-[60%] xl:w-[40%]"
              style={{ fontFamily: "Poppins, sans-serif" }}
             
            >
              Enquiry Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default YouMayLike;
