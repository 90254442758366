import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Swiper.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import img1 from '../../Assets/homeimgs/hero_section_imgs.png'
import img2 from '../../Assets/homeimgs/img1.png'
import img3 from '../../Assets/homeimgs/img2.png'
import img4 from '../../Assets/homeimgs/img3.png';
const HeroSEction = () => {
  return (
    <div>
        <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
       
        <SwiperSlide>
        <img src={img2} alt='img' className='w-full'/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img3} alt='img' className='w-full'/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img4} alt='img' className='w-full'/>
        </SwiperSlide>
        {/* <SwiperSlide>
        <img src={img} alt='img' className='w-full'/>
        </SwiperSlide> */}
       
      </Swiper>
       
    </div>
  )
}

export default HeroSEction