import React from 'react'
import SimpleBanner from '../Common/SimpleBanner';
import DetailSection from '../../Components/TourDetails/DetailiSection';
import ClientsTestimonials from '../../Components/About Us/ClientsTestimonials';
import YouMayLike from '../../Components/TourDetails/YouMayLike';
import Newsletter from '../../Components/home/Newsletter';
import Aboutbanar from '../../Assets/TourDetails/topbanner.png'
import RamMandir from '../../Assets/Tour/RamMandir.png';
import Sradha from '../../Assets/homeimgs/Sradha.webp';
import Pritam from '../../Assets/TourDetails/pritam.jpg'

const RamMandirTour = () => {
  const tripData = {
    title: "Explore the Divine Beauty of Ram Mandir, Bhubaneswar",
    imageUrl: RamMandir, // Replace with the actual image path
    details: [
      { icon: "calendar_icon", label: "Duration", value: "1 day" },
      { icon: "people_icon", label: "Max People", value: "80" },
      { icon: "age_icon", label: "Min Age", value: "5+" },
      { icon: "location_icon", label: "Pick Up", value: "Bhubaneswar Railway Station" }
    ],

    content: {
      description: "Ram Mandir in Bhubaneswar is a serene temple dedicated to Lord Ram. Known for its architectural splendor and tranquil surroundings, the temple is an important center of spirituality in the region, attracting visitors seeking peace and divine blessings.",
      program: {
        summary: "This one-day visit will guide you through the magnificent temple structure, the rituals dedicated to Lord Ram, and its spiritual importance in the city of Bhubaneswar. The visit also includes a tour of the temple's surroundings.",
        days: [
          {
            day: 1,
            title: "Ram Mandir Visit",
            description: "Begin with a visit to the Ram Mandir, admire its grand architecture, and participate in the morning aarti. Learn about the history of the temple and its significance in Hindu culture, followed by a peaceful meditation session within the temple precincts."
          }
        ]
      },
      review: {
        rating: 4.7,
        ratingText: "Spiritual & Tranquil",
        ratingDescription: "Ram Mandir in Bhubaneswar is a serene and spiritually significant temple, offering peaceful surroundings, stunning architecture, and a vibrant atmosphere for devotees.",
        reviewCount: 25,
        summary: "The Ram Mandir offers a peaceful atmosphere, perfect for reflection and spiritual growth. Its architecture is beautiful, and the rituals are heart-touching, making it a must-visit destination in Bhubaneswar.",
        reviews: [
          {
            reviewerName: "Sradha Mandal",
            reviewerImage: Sradha, // Replace with the actual image path
            rating: 5,
            reviewText: "The temple is simply beautiful, and the calmness here is unmatched. The rituals were meaningful, and the peaceful vibes made it a divine experience.",
            replyText: "Reply"
          },
          {
            reviewerName: "Pritam Choudhury",
            reviewerImage: Pritam, // Replace with the actual image path
            rating: 4,
            reviewText: "A spiritual visit worth every moment. The temple’s architecture is magnificent, and the location offers a calm escape from the city's hustle.",
            replyText: "Reply"
          }
        ]
      },
      mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29940.38141980798!2d85.80532247431641!3d20.27758559999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a7f24cf1d2dd%3A0x5e094527c1d2d7fc!2sShri%20Ram%20Temple%2C%20Bhubaneswar!5e0!3m2!1sen!2sin!4v1731675963315!5m2!1sen!2sin"
    }
  }
  return (
    <div>
      <SimpleBanner
        image={Aboutbanar}
        name="Tour Details"
        path="Home / Tour Details"
      />
      <DetailSection tripData={tripData} />
      <ClientsTestimonials />
      <YouMayLike />
      <Newsletter />
    </div>
  )
}

export default RamMandirTour