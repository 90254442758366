import React from 'react'
import SimpleBanner from '../Common/SimpleBanner';
import DetailSection from '../../Components/TourDetails/DetailiSection';
import ClientsTestimonials from '../../Components/About Us/ClientsTestimonials';
import YouMayLike from '../../Components/TourDetails/YouMayLike';
import Newsletter from '../../Components/home/Newsletter';
import Aboutbanar from '../../Assets/TourDetails/topbanner.png'
import Gopalpur from '../../Assets/Tour/Gopalpur.png';
import Tanya from '../../Assets/homeimgs/Tanya.jpg';

import Jeeban from '../../Assets/homeimgs/jeeban.jpg';

const GopalpurTour = () => {
    const tripData = {
        title: "Relax and Unwind at Gopalpur Sea Beach",
        imageUrl: Gopalpur, // Replace with the actual image path
        details: [
          { icon: "calendar_icon", label: "Duration", value: "5 days" },
          { icon: "people_icon", label: "Max People", value: "25" },
          { icon: "age_icon", label: "Min Age", value: "8+" },
          { icon: "location_icon", label: "Pick Up", value: "Berhampur Railway Station" }
        ],
      
        content: {
          description: "Experience the serene beauty of Gopalpur Sea Beach, a tranquil seaside destination on the Bay of Bengal. Known for its golden sands, gentle waves, and charming sunsets, Gopalpur is perfect for a relaxing getaway.",
          program: {
            summary: "This five-day journey to Gopalpur Sea Beach includes leisure activities, beach fun, local cuisine, and a glimpse into the rich history of this coastal town. Enjoy stunning sea views, indulge in water sports, and unwind in a peaceful environment.",
            days: [
              {
                day: 1,
                title: "Arrival at Berhampur & Transfer to Gopalpur",
                description: "Arrive at Berhampur Railway Station and transfer to your beach resort at Gopalpur. Take a leisurely walk along the beach and enjoy the sunset over the Bay of Bengal."
              },
              {
                day: 2,
                title: "Beach Day & Water Sports",
                description: "Spend the day at Gopalpur Beach with a variety of water activities, including surfing, jet skiing, and paddleboarding. Relax on the sun-kissed sands and enjoy fresh seafood from local vendors."
              },
              {
                day: 3,
                title: "Visit to Gopalpur Lighthouse & Local Market",
                description: "Explore the historic Gopalpur Lighthouse for panoramic views of the coastline. Later, visit the local market for traditional handicrafts, seafood, and souvenirs."
              },
              {
                day: 4,
                title: "Excursion to Rushikulya Turtle Sanctuary",
                description: "Take a half-day trip to the nearby Rushikulya Turtle Sanctuary, famous for the nesting of Olive Ridley turtles. Learn about local conservation efforts and enjoy a picnic by the sea."
              },
              {
                day: 5,
                title: "Departure from Gopalpur",
                description: "Enjoy a final morning at the beach, soaking in the calm and refreshing breeze. Check out from the resort and transfer back to Berhampur Railway Station for your journey home."
              }
            ]
          },
          review: {
            rating: 4.7,
            ratingText: "Very Good",
            ratingDescription: "Gopalpur Sea Beach offers a peaceful retreat with its golden sands, serene waters, and breathtaking sunsets—ideal for relaxation and scenic beauty.",
            reviewCount: 42,
            summary: "Gopalpur Sea Beach offers a perfect blend of relaxation and adventure. The beach's clean sands, local cuisine, and peaceful environment make it a top choice for a coastal getaway.",
            reviews: [
              {
                reviewerName: "Tanya Chakraborty",
                reviewerImage: Tanya, // Replace with the actual image path
                rating: 5,
                reviewText: "Absolutely loved the peaceful atmosphere and the local seafood. The beach was clean, and the water sports added a fun twist to the trip!",
                replyText: "Reply"
              },
              {
                reviewerName: "Aditya Kumar Behera",
                reviewerImage: Jeeban, // Replace with the actual image path
                rating: 4,
                reviewText: "A great destination for a short beach vacation. The lighthouse view was stunning, and the turtle sanctuary visit was a unique experience.",
                replyText: "Reply"
              }
            ]
          },
          mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19182.77292822583!2d84.90241641250768!3d19.27651727476367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3bbd5722eecee9%3A0xafb91d95ad5f55b7!2sGopalpur%20Sea%20Beach%2C%20Odisha!5e0!3m2!1sen!2sin!4v1731786543210!5m2!1sen!2sin"
        }
      };
      
  return (
    <div>
          <SimpleBanner
        image={Aboutbanar}
        name="Tour Details"
        path="Home / Tour Details"
      />
      <DetailSection tripData={tripData}/>
      <ClientsTestimonials />
      <YouMayLike />
      <Newsletter />
    </div>
  )
}

export default GopalpurTour