import React from "react";
import SimpleBanner from "../Common/SimpleBanner";
import DetailSection from "../../Components/TourDetails/DetailiSection";
import ClientsTestimonials from "../../Components/About Us/ClientsTestimonials";
import YouMayLike from "../../Components/TourDetails/YouMayLike";
import Newsletter from "../../Components/home/Newsletter";
import Aboutbanar from "../../Assets/TourDetails/topbanner.png";
import DhauliGiri from "../../Assets/Tour/Dhauli.jpg";
import Anita from '../../Assets/TourDetails/anita.jpg'
import Rabi from '../../Assets/TourDetails/rabi.jpg';

const DhauliGiriToure = () => {
  const tripData = {
    title: "Discover the Historical Significance of DhauliGiri",
    imageUrl: DhauliGiri,
    details: [
      { icon: "calendar_icon", label: "Duration", value: "1 day" },
      { icon: "people_icon", label: "Max People", value: "50" },
      { icon: "age_icon", label: "Min Age", value: "5+" },
      {
        icon: "location_icon",
        label: "Pick Up",
        value: "Bhubaneswar Railway Station",
      },
    ],

    content: {
      description:
        "Explore the historical and spiritual site of DhauliGiri, known for the famous Shanti Stupa and the transformation of Emperor Ashoka. A serene destination with deep cultural and historical roots, offering a glimpse into India's Buddhist heritage.",
      program: {
        summary:
          "This one-day excursion to DhauliGiri focuses on exploring its rich historical legacy, the Dhauligiri Shanti Stupa, Ashokan edicts, and the beautiful scenery around this peaceful location.",
        days: [
          {
            day: 1,
            title: "DhauliGiri Exploration",
            description:
              "Start with a visit to the famous Shanti Stupa, a symbol of peace and spirituality. Learn about the historic Kalinga War, explore the Ashokan edicts inscribed on rocks, and enjoy the panoramic views of the Daya River. The trip concludes with a peaceful meditation session at the stupa.",
          },
        ],
      },
      review: {
        rating: 4.6,
        ratingText: "Historical & Peaceful",
        ratingDescription: "DhauliGiri offers tranquility and history, showcasing Ashoka’s transformation with the serene Shanti Stupa and scenic Daya River views—a peaceful retreat.",
        reviewCount: 30,
        summary:
          "DhauliGiri is a place of historical importance and spiritual tranquility. The visit provides insight into Emperor Ashoka's transformation and the spread of Buddhism in India.",
        reviews: [
          {
            reviewerName: "Anita Verma",
            reviewerImage: Anita, // Replace with the actual image path
            rating: 5,
            reviewText:
              "A wonderful trip into history. The peaceful atmosphere of the Shanti Stupa and the breathtaking view of the Daya River were mesmerizing.",
            replyText: "Reply",
          },
          {
            reviewerName: "Ravi Samantray",
            reviewerImage: Rabi, // Replace with the actual image path
            rating: 4,
            reviewText:
              "A historically enriching experience. The stories about Ashoka’s transformation were inspiring. A perfect place for those who appreciate history and spirituality.",
            replyText: "Reply",
          },
        ],
      },
      mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3744.6027034225694!2d85.83453849678955!3d20.192261099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909ff47a6b839%3A0x41e4c5c0a7d51f9f!2sDhauli%20Shanti%20Stupa!5e0!3m2!1sen!2sin!4v1731674024424!5m2!1sen!2sin",
    },
  };
  return (
    <div>
      <SimpleBanner
        image={Aboutbanar}
        name="Tour Details"
        path="Home / Tour Details"
      />
      <DetailSection tripData={tripData} />
      <ClientsTestimonials />
      <YouMayLike />
      <Newsletter />
    </div>
  );
};

export default DhauliGiriToure;
