import React from "react";
import Dhauligiri from "../../Assets/Tour/Dhauligiri.png";
import Chilika from "../../Assets/Tour/Chilika.png";
import Gopalpur from "../../Assets/Tour/Gopalpur.png";
import JagannathTemple from "../../Assets/Tour/JagannathTempleTour.png";
import RamMandir from "../../Assets/Tour/RamMandir.png";
import ISKCON from "../../Assets/Tour/ISKCONTemple.png";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    img: Dhauligiri,
    name: "Dhauligiri, Bhubaneswar",
    description:
      "A historic hilltop, famous for the Peace Pagoda and ancient rock edicts, offering panoramic views and a tranquil atmosphere.",
    url:"/tourDetails/dhauliGiriTour",
    aos: "flip-left"
  },

  {
    id: 2,
    img: Chilika,
    name: "Chilika Lake, Odisha",
    description:
      "A vast brackish water lagoon, known for its bird sanctuary, boat rides, and rich biodiversity, making it a nature lover’s paradise.",
      url:"/tourDetails/chilikaTour",
    aos: "zoom-in"
  },

  {
    id: 3,
    img: Gopalpur,
    name: "Gopalpur Sea Beach",
    description:
      "A serene coastal destination offering picturesque views, peaceful walks, and relaxing moments by the sparkling waters of the Bay of Bengal.",
      url:"/tourDetails/gopalpurTour",
    aos: "flip-right"
  },

  {
    id: 4,
    img: JagannathTemple,
    name: "Jagannath Temple, Puri",
    description:
      "A renowned pilgrimage site dedicated to Lord Jagannath, famous for its grand chariot festival and spiritual significance in Odisha.",
      url:"/tourDetails/jagannathTempleTour",
    aos: "flip-left"
  },

  {
    id: 5,
    img: RamMandir,
    name: "Ram Mandir, Bhubaneswar",
    description:
      "A majestic temple dedicated to Lord Ram, known for its peaceful atmosphere, intricate carvings, and beautiful surroundings.",
      url:"/tourDetails/ramMandirTour",
    aos: "zoom-in"
  },

  {
    id: 6,
    img: ISKCON,
    name: "ISKCON Temple, Bhubaneswar",
    description:
      "A serene spiritual haven offering peace, devotion, and vibrant cultural experiences, with stunning architecture and soulful prayers.",
      url:"/tourDetails/iskconTempleTour",
    aos: "flip-right"
  }
];

const TrendingTours = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-6 md:mx-16 my-4 md:my-8 flex flex-col items-center">
      <h1
        className="text-3xl md:text-4xl font-bold text-[#292929] tracking-wide mb-2 text-center"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        Trending Tour
      </h1>
      <p className="text-[#767676] w-full lg:w-[50%] mb-4 capitalize leading-relaxed text-center">
      Explore the latest must-visit destinations, with exciting itineraries, exclusive experiences, and unforgettable memories at unbeatable prices.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {data.map((data) => (
          <div key={data.id} className="flex flex-col"data-aos={data.aos}>
            <img src={data.img} alt="" className="mb-4" />
            <h1
              className="mb-2 font-bold text-[#292929] text-xl"
              style={{ fontFamily: "DM Sans, sans-serif" }}
            >
              {data.name}
            </h1>
            <p
              className="text-[#767676] text-base leading-relaxed mb-4"
              style={{ fontFamily: "Lato, sans-serif" }}
            >
              {data.description}
            </p>

            <button
            onClick={()=>{navigate(`${data.url}`); window.scrollTo(600, 0);}}
              type="submit"
              className="bg-[#FBA710] hover:bg-[#de7a01] text-white px-6 py-3 font-medium rounded shadow-xl w-full lg:w-[60%] xl:w-[40%]"
              style={{ fontFamily: "Poppins, sans-serif" }}
             
            >
              Enquiry Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingTours;
//