import React from "react";
import localGuide from "../../Assets/About/tour-guide.png";
import localGuideWhite from "../../Assets/About/tour-guide-white.png";
import satisfaction from "../../Assets/About/satisfaction.png";
import satisfactionWhite from "../../Assets/About/satisfaction-white.png";
import lowPrice from "../../Assets/About/low-cost.png";
import lowPriceWhite from "../../Assets/About/low-cost-white.png";
import driver from "../../Assets/About/driver.png";
import driverWhite from "../../Assets/About/driver-white.png";

const data = [
  {
    id: 1,
    img1: localGuide,
    img2: localGuideWhite,
    heading: "Local Tour Guide",
    description:"Expert guides showcasing hidden gems and cultural highlights of your destination.",
    aos: "flip-left"
  },

  {
    id: 2,
    img1: satisfaction,
    img2: satisfactionWhite,
    heading: "Customer Satisfaction",
    description:
      " Prioritizing exceptional service to ensure every journey is memorable and delightful.",
    aos: "flip-left"
  },

  {
    id: 3,
    img1: lowPrice,
    img2: lowPriceWhite,
    heading: "Affordable Price",
    description:
      "High-quality experiences tailored to your budget for unbeatable value.",
    aos: "flip-left"
  },

  {
    id: 4,
    img1: driver,
    img2: driverWhite,
    heading: "Proffessional Driver",
    description:
      "Skilled, courteous drivers ensuring safe and smooth travel every time.",
    aos: "flip-left"
  },
];

function WhyChooseUs() {
  return (
    <div className="mx-6 md:mx-16 my-6 md:my-16 flex flex-col items-center">
      <h1
        className="text-3xl md:text-4xl font-bold text-center text-[#292929] tracking-wide mb-4"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        Why Choose Us?
      </h1>
      <p className="text-[#767676] w-full lg:w-[50%] mb-6 capitalize text-center leading-relaxed font-semibold">
      Experience exceptional service, expert guidance, affordable pricing, and reliable professionals dedicated to making your journey unforgettable.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {data.map((data) => (
          <div className="flex flex-col items-center text-center p-8 lg:p-4 xl:p-8 border border-[#CCCACA] group hover:bg-[#FBA710] transition-all duration-300 ease-in-out rounded "data-aos={data.aos}>
            <img
              src={data.img1}
              alt={data.heading}
              className="mb-4 group group-hover:hidden"
            />
            <img
              src={data.img2}
              alt=""
              className="mb-4 hidden group group-hover:block"
            />

            <h1
              className="text-xl font-bold text-[#292929] mb-4 group group-hover:text-white transition-all duration-300 ease-in-out"
              style={{ fontFamily: "DM Sans, sans-serif" }}
            >
              {data.heading}
            </h1>
            <p
              className="text-md text-[#767676] group-hover:text-white transition-all duration-300 ease-in-out leading-relaxed"
              style={{ fontFamily: "Lato, sans-serif" }}
            >
              {data.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhyChooseUs;
