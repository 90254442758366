import React from "react";
import StayConnected from "../../Components/Contact/StayConnected";
import Map from "../../Components/Contact/Map";
import SimpleBanner from "../Common/SimpleBanner";
import TourBanner from "../../Assets/Contact/contactbanner.png";
import Newsletter from "../../Components/home/Newsletter";
const Contact = () => {
  return (
    <div className="overflow-hidden">
      <SimpleBanner
        image={TourBanner}
        name="Contact Us"
        path="Home / Contact Us"
      />
      <StayConnected />
      <Map location="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.9075260048776!2d85.81316697482623!3d20.262669581201795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a738f7d5382f%3A0xb9c2c6c215787f8a!2sGoCabz%20Travels%20Bhubaneswar%20Taxi%20Services!5e0!3m2!1sen!2sin!4v1731742964631!5m2!1sen!2sin"/>
      <Newsletter />
      
    </div>
  );
};

export default Contact;
