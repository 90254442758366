import React from 'react'
import Gallery1 from '../../Assets/Gallery/Frame 1000003627.png'
import Gallery2 from '../../Assets/Gallery/Frame 1000003628.png'
import Gallery3 from '../../Assets/Gallery/Frame 1000003629.png'
import Gallery4 from '../../Assets/Gallery/Frame 1000003630.png'
import Gallery5 from '../../Assets/Gallery/Frame 1000003631.png'
import Gallery6 from '../../Assets/Gallery/Frame 1000003632.png'
import Gallery7 from '../../Assets/Gallery/Frame 1000003633.png'
import Gallery8 from '../../Assets/Gallery/Frame 1000003634.png'
import Gallery9 from '../../Assets/Gallery/Frame 1000003635.png'
import Gallery10 from '../../Assets/Gallery/Frame 1000003636.png'
import Gallery11 from '../../Assets/Gallery/Frame 1000003637.png'
import Gallery12 from '../../Assets/Gallery/Frame 1000003638.png'
import Gallery13 from '../../Assets/Gallery/Frame 1000003639.png'
import Gallery14 from '../../Assets/Gallery/Frame 1000003640.png'
import Gallery15 from '../../Assets/Gallery/Frame 1000003641.png'
import Gallery16 from '../../Assets/Gallery/Frame 1000003642.png'
import Gallery17 from '../../Assets/Gallery/Frame 1000003643.png'
import Gallery18 from '../../Assets/Gallery/Frame 1000003644.png'
import Gallery19 from '../../Assets/Gallery/Frame 1000003645.png'
import Gallery20 from '../../Assets/Gallery/Frame 1000003646.png'
import Gallery21 from '../../Assets/Gallery/Frame 1000003647.png'
import Gallery22 from '../../Assets/Gallery/Frame 1000003648.png'
import Gallery23 from '../../Assets/Gallery/Frame 1000003649.png'
import Gallery24 from '../../Assets/Gallery/Frame 1000003650.png'
import Gallery25 from '../../Assets/Gallery/Frame 1000003651.png'
import Gallery26 from '../../Assets/Gallery/Frame 1000003652.png'
import Gallery27 from '../../Assets/Gallery/Frame 1000003653.png'

const GalleryImg = () => {
  return (
    <>
    <h1  className="text-3xl mt-10 md:text-4xl font-bold text-center text-[#292929] tracking-wide mb-8 capitalize"
        style={{ fontFamily: "DM Sans, sans-serif" }} data-aos="flip-up">“We do not remember days, we remember moments.”</h1>
      <div className='grid lg:grid-cols-3 mt-14 md:grid-cols-2 grid-cols-1 md:mx-16 mx-6 gap-12'>
        <img className='mx-auto' src={Gallery1} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery2} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery3} alt='img' data-aos="fade-down-left"/>
        <img className='mx-auto' src={Gallery4} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery5} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery6} alt='img' data-aos="fade-down-left"/>
        <img className='mx-auto' src={Gallery7} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery8} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery9} alt='img' data-aos="fade-down-left"/>
        <img className='mx-auto' src={Gallery10} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery11} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery12} alt='img' data-aos="fade-down-left"/>
        <img className='mx-auto' src={Gallery13} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery14} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery15} alt='img' data-aos="fade-down-left"/>
        <img className='mx-auto' src={Gallery16} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery17} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery18} alt='img' data-aos="fade-down-left"/>
        <img className='mx-auto' src={Gallery19} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery20} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery21} alt='img' data-aos="fade-down-left"/>
        <img className='mx-auto' src={Gallery22} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery23} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery24} alt='img' data-aos="fade-down-left"/>
        <img className='mx-auto' src={Gallery25} alt='img' data-aos="fade-down-right"/>
        <img className='mx-auto' src={Gallery26} alt='img' data-aos="zoom-in-up"/>
        <img className='mx-auto' src={Gallery27} alt='img' data-aos="fade-down-left"/>
      </div>
    </>
  )
}

export default GalleryImg