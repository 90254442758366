import React from "react";
import Mission from "../../Assets/About/MissionImage.png";
import Vision from "../../Assets/About/VisionImage.png";

function VisionMission() {
  return (
    <div className="mx-6 md:mx-16 my-6 md:my-16 ">
      <div className="flex flex-col md:flex-row text-center items-center justify-center mb-16 md:mb-8">
        <div
          className="w-full md:w-[50%] flex flex-col items-center p-4 xl:px-24"
          style={{ fontFamily: "Poppins, sans-serif" }}
          data-aos="fade-right"
        >
          <h1 className="text-xl text-[#FBA710] font-semibold text-center flex flex-col mb-4">
            VISION
          </h1>
          <hr className="border-t-2 border-[#FBA710] w-[70%] mb-2" />
          <hr className="border-t-2 border-[#FBA710] w-[70%] mb-4" />
          <p className="mt-4 text-base opacity-70 tracking-wide capitalize">
            Our vision is to be the leading travel agency in Odisha, known for
            our commitment to excellence, innovation, and customer satisfaction.
            We aspire to expand our services nationwide, creating unforgettable
            travel experiences and becoming a trusted name in the travel and
            tourism industry.
          </p>
        </div>
        <img src={Vision} alt="" className="w-full md:w-[50%]" data-aos="fade-down"/>
      </div>

      <div className="flex flex-col md:flex-row text-center items-center justify-center">
        <img src={Mission} alt="" className="w-full md:w-[50%]" data-aos="fade-down"/>
        <div
          className="w-full md:w-[50%] flex flex-col items-center p-4 xl:px-24"
          style={{ fontFamily: "Poppins, sans-serif" }}
          data-aos="fade-left"
        >
          <h1 className="text-xl text-[#FBA710] font-semibold text-center flex flex-col mb-4">
            MISSION
          </h1>
          <hr className="border-t-2 border-[#FBA710] w-[70%] mb-2" />
          <hr className="border-t-2 border-[#FBA710] w-[70%] mb-4" />
          <p className="mt-4 text-base opacity-70 tracking-wide capitalize">
            At We Go-Cabz, our mission is to provide exceptional and reliable
            travel experiences by offering top-quality transportation services
            tailored to our clients’ needs. We aim to ensure safety, comfort,
            and convenience for every journey while maintaining the highest
            standards of customer service. Our goal is to be the preferred
            travel partner for both locals and tourists in Odisha.
          </p>
        </div>
      </div>
    </div>
  );
}

export default VisionMission;
